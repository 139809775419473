/* ManageAds.css */

/* Reset some basic elements */
body, h2, form, input, textarea, button {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* General styles */
  body {
    font-family: Arial, sans-serif;
    background-color: #f5f6fa;
  }
  
  .manage-ads-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .manage-ads-content {
    flex: 1;
    max-width: 600px;
    margin: 40px auto;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .manage-ads-content h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #333333;
  }
  
  /* Form styles */
  form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
    color: #555555;
  }
  
  .form-group input[type="text"],
  .form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-group textarea {
    min-height: 120px;
    resize: vertical;
  }
  
  .form-group input[type="file"] {
    font-size: 16px;
  }
  
  .upload-button {
    background-color: #007bff;
    color: #ffffff;
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }
  
  .upload-button:hover {
    background-color: #0056b3;
  }
  
  .upload-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  /* Responsive design */
  @media (max-width: 600px) {
    .manage-ads-content {
      margin: 20px;
      padding: 20px;
    }
  
    .upload-button {
      width: 100%;
    }
  }
  