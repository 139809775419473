/* MyOrders.css */

.my-orders {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 18px;
  color: #666;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error {
  text-align: center;
  color: #e74c3c;
  font-size: 18px;
  margin-top: 20px;
}

.order-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  overflow: hidden;
}

.order-header {
  background-color: #f8f9fa;
  padding: 15px 20px;
  border-bottom: 1px solid #e9ecef;
}

.order-header h2 {
  margin: 0;
  color: #2c3e50;
  font-size: 1.4em;
}

.order-header p {
  margin: 5px 0 0;
  color: #7f8c8d;
  font-size: 0.9em;
}

.order-details {
  padding: 20px;
}

.order-item {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ecf0f1;
}

.order-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.product-image {
  width: 100px;
  height: 150px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 4px;
}

.product-info {
  flex-grow: 1;
}

.product-info h3 {
  margin: 0 0 5px;
  color: #34495e;
  font-size: 1.2em;
}

.product-info p {
  margin: 5px 0;
  color: #7f8c8d;
  font-size: 0.9em;
}

.product-info p strong {
  color: #2c3e50;
}

@media (max-width: 600px) {
  .order-item {
    flex-direction: column;
  }

  .product-image {
    width: 100%;
    height: 200px;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

/* Existing styles... */

/* Positioning the order-header to relative */
.order-header {
  position: relative;
  padding: 15px;
  background-color: #f5f5f5;
}

/* Styling the "Log a Dispute" button */
.log-dispute-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: darkred;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.log-dispute-button:hover {
  background-color: darkred;
}

/* Ensure that the h2 and p elements have appropriate margins */
.order-header h2 {
  margin: 0;
  margin-bottom: 5px;
}

.order-header p {
  margin: 0;
  color: #777;
}

/* Rest of your styles... */

/* Styles for loading spinner */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #FF1616; /* Color of the spinner */
  width: 80px;
  height: 80px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container p {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
}


@media (max-width: 480px) {
  .categories-content {
    grid-template-columns: 1fr;
  }
  
  .genre-group h2 {
    font-size: 1.8rem;
  }

  
}