/* SearchResultsPage.css */

/* Container for the entire search results page */
.search-results-page-container {
    min-height: 60vh; /* Adjust to your preference */
    padding: 1rem 2rem;
    background-color: #f9f9f9; /* Light background */
  }
  
  /* Spinner container */
  .search-results-loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
  
  /* Simple loading spinner animation */
  .search-results-loading-spinner {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.0s linear infinite;
    margin-bottom: 1rem;
  }
  
  /* Keyframes for spinner */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Title */
  .search-results-title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  /* Error message */
  .search-results-error {
    color: red;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  /* No match message */
  .search-results-no-match {
    text-align: center;
    color: #777;
  }
  
  /* List container for search results */
  .search-results-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
    margin-top: 1rem;
  }
  
  /* Each result item */
  .search-results-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 1rem;
    text-align: center;
  }
  
  /* Heading for each item */
  .search-results-item-name {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  /* Image styles */
  .search-results-item-image {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    margin-bottom: 0.5rem;
  }
  
  /* Item description, etc. */
  .search-results-item-description {
    font-size: 0.95rem;
    color: #555;
    margin: 0.5rem 0;
  }
  
  /* Add to Cart and Wishlist Buttons */
.button-container {
  display: flex;
  gap: 10px; /* Space between buttons */
  margin-top: 10px; /* Space above buttons */
}

.button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.button-cart {
  background-color: #4CAF50; /* Green for Add to Cart */
  color: white;
}

.button-cart:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.button-wishlist {
  background-color: #FF6347; /* Tomato for Add to Wishlist */
  color: white;
}

.button-wishlist:hover {
  background-color: #FF4500;
  transform: scale(1.05);
}

/* Responsive Design for Buttons */
@media (max-width: 768px) {
  .button {
    font-size: 12px;
    padding: 8px 12px;
  }

  .button-container {
    flex-direction: column; /* Stack buttons vertically */
    align-items: stretch; /* Stretch buttons to container width */
  }
}
