/* Footer.css */

/* Global Styles */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
}

/* Main content area that expands to fill available space */
.main-content {
  flex: 1;
}

/* Footer Styles */
.footer {
  background-color: #1a1a1a;
  color: #f4f4f4;
  padding: 40px 20px;
  font-size: 16px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.footer-section {
  flex: 1 1 250px;
  margin-bottom: 20px;
}

.footer-section h4 {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 18px;
}

.footer-section p,
.footer-section ul {
  color: #f4f4f4;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #f4f4f4;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: #ff4500;
}

.footer-bottom {
  text-align: center;
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid #444;
  font-size: 14px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    text-align: center;
  }

  .footer-section ul li {
    margin-bottom: 5px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 30px 15px;
  }

  .footer-section h4 {
    font-size: 16px;
  }

  .footer-section p,
  .footer-section ul li a {
    font-size: 14px;
  }

  .footer-bottom {
    font-size: 12px;
  }
}
