/* src/style/Announce/AnnouncePage.css */

/* General Styles */
.announce-page-container {
    background-color: #f9fafb;
    min-height: 80vh;
    padding: 2rem 1rem;
  }
  
  .announce-form-wrapper {
    max-width: 700px;
    margin: 0 auto;
    background-color: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .page-title {
    font-size: 2rem;
    font-weight: bold;
    color: #1f2937;
    text-align: center;
    margin-bottom: 1rem;
    border-bottom: 2px solid #3b82f6;
    padding-bottom: 0.5rem;
  }
  
  .description {
    font-size: 1rem;
    color: #4b5563;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  /* Form Styles */
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  label {
    display: block;
    font-weight: 600;
    color: #4b5563;
    margin-bottom: 0.5rem;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    font-size: 1rem;
    color: #1f2937;
  }
  
  textarea {
    resize: vertical;
  }
  
  input:focus,
  textarea:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.2);
  }
  
  /* Button Styles */
  .submit-button {
    display: inline-block;
    background-color: #3b82f6;
    color: #ffffff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.375rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.2s;
    width: 100%;
  }
  
  .submit-button:hover {
    background-color: #2563eb;
  }
  
  .submit-button:disabled {
    background-color: #9ca3af;
    cursor: not-allowed;
  }
  
  /* Toast Notifications */
  .toast-container {
    z-index: 9999;
  }
  