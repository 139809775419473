/* Wishlist.css */

/* General Styles */
.wishlist-page {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
  padding-top: 80px; /* Adjust this value based on your header height */
}

.wishlist-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.wishlist-title {
  font-size: 2rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1rem;
  border-bottom: 2px solid #b91c1c;
  padding-bottom: 0.5rem;
}

.wishlist-empty-message {
  font-size: 1.25rem;
  color: #4b5563;
  text-align: center;
  margin-top: 2rem;
}

/* Wishlist Items List */
.wishlist-items-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.wishlist-item {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  display: flex;
  padding: 1rem;
  margin-bottom: 1.5rem;
  align-items: flex-start;
}

.wishlist-item-image-container {
  width: 120px;
  height: 180px;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 1.5rem;
}

.wishlist-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wishlist-item-info {
  flex: 1;
}

.wishlist-item-name {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0.5rem;
}

.wishlist-item-author,
.wishlist-item-price,
.wishlist-item-isbn,
.wishlist-item-genre {
  font-size: 1rem;
  color: #4b5563;
  margin-bottom: 0.25rem;
}

.wishlist-item-description {
  font-size: 0.9rem;
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.wishlist-item-actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-left: 1.5rem;
}

.wishlist-item-action-button {
  background-color: #b91c1c;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.wishlist-item-action-button:hover {
  background-color: #991b1b;
}

.remove-button {
  background-color: #dc2626;
}

.remove-button:hover {
  background-color: #b91c1c;
}

.move-button {
  background-color: #2563eb;
}

.move-button:hover {
  background-color: #1d4ed8;
}

/* Loading and Error Messages */
.wishlist-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.wishlist-loading-spinner {
  border: 4px solid #e5e7eb;
  border-top: 4px solid #b91c1c;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.wishlist-error-message {
  color: #dc2626;
  background-color: #fef2f2;
  padding: 1rem;
  border-radius: 0.375rem;
  max-width: 600px;
  margin: 2rem auto;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .wishlist-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .wishlist-item-image-container {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .wishlist-item-actions {
    flex-direction: row;
    justify-content: center;
    margin-left: 0;
    margin-top: 1rem;
  }

  .wishlist-item-action-button {
    flex: 1;
    margin: 0 0.5rem;
  }
}

@media (max-width: 480px) {
  .wishlist-title {
    font-size: 1.5rem;
  }

  .wishlist-item-name {
    font-size: 1rem;
  }

  .wishlist-item-author,
  .wishlist-item-price,
  .wishlist-item-isbn,
  .wishlist-item-genre {
    font-size: 0.9rem;
  }

  .wishlist-item-description {
    font-size: 0.8rem;
  }

  .wishlist-item-action-button {
    font-size: 0.8rem;
    padding: 0.5rem;
  }

  .wishlist-item-image-container {
    width: 100px;
    height: 150px;
  }
}

/* Wishlist Page Specific Styles */
.wishlist-page-container {
  min-height: 100vh;
  position: relative;
}

.wishlist-page-container header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: darkred;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
