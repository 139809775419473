/* General Styles */
.manage-sales-page {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.manage-sales-title {
  font-size: 2rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 2rem;
  text-align: center;
}

/* Categories List */
.categories-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.category-card {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  width: 100%;
  max-width: 350px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.category-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
}

.category-info {
  padding: 1rem;
  text-align: center;
}

.category-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 0.5rem;
}

.category-description,
.category-genre,
.category-price,
.category-quantity {
  font-size: 1rem;
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.category-actions {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  width: 100%;
  justify-content: center;
}

.edit-button,
.remove-button {
  flex: 1;
  padding: 0.75rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.edit-button {
  background-color: #3b82f6;
  color: #ffffff;
}

.edit-button:hover {
  background-color: #2563eb;
}

.remove-button {
  background-color: #ef4444;
  color: #ffffff;
}

.remove-button:hover {
  background-color: #dc2626;
}

/* Edit Category Form */
.edit-category-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
}

.edit-category-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1rem;
  text-align: center;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 1rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0.5rem;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group input[type="file"] {
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: #1f2937;
}

.form-group input:focus {
  border-color: #3b82f6;
  outline: none;
}

.category-image-preview {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

.form-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.save-button,
.cancel-button {
  flex: 1;
  padding: 0.75rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.save-button {
  background-color: #10b981;
  color: #ffffff;
}

.save-button:hover {
  background-color: #059669;
}

.cancel-button {
  background-color: #6b7280;
  color: #ffffff;
}

.cancel-button:hover {
  background-color: #4b5563;
}

/* Loading and Error Messages */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.loading-spinner {
  border: 4px solid #e5e7eb;
  border-top: 4px solid #3b82f6;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.error-message {
  color: #dc2626;
  background-color: #fef2f2;
  padding: 1rem;
  border-radius: 0.5rem;
  max-width: 600px;
  margin: 2rem auto;
  text-align: center;
}

/* No Categories Message */
.no-categories-message {
  font-size: 1.25rem;
  color: #6b7280;
  text-align: center;
  margin-top: 2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .category-card {
    width: 100%;
    max-width: none;
  }

  .category-actions {
    flex-direction: column;
    gap: 0.5rem;
  }

  .edit-button,
  .remove-button {
    width: 100%;
  }

  .form-actions {
    flex-direction: column;
    gap: 0.5rem;
  }

  .save-button,
  .cancel-button {
    width: 100%;
  }
}
