/* General Styles */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 2rem;
  background-color: #f9fafb;
}

.login-form-container {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  padding: 2rem;
  max-width: 500px;
  width: 100%;
}

.login-heading {
  text-align: center;
  margin-bottom: 1.5rem;
}

.login-heading h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 0.5rem;
}

.login-heading p {
  font-size: 1rem;
  color: #4b5563;
}

/* Login Form */
.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Form Groups */
.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 1rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0.5rem;
}

.form-group input {
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 1rem;
  color: #1f2937;
  transition: border-color 0.2s;
}

.form-group input:focus {
  border-color: #2563eb;
  outline: none;
}

.error-input {
  border-color: #dc2626;
}

/* Username Input with @ Symbol */
.username-input {
  display: flex;
  align-items: center;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
}

.username-input span {
  padding: 0 0.75rem;
  background-color: #f3f4f6;
  border-right: 1px solid #d1d5db;
  color: #6b7280;
  font-size: 1rem;
}

.username-input input {
  flex: 1;
  padding: 0.75rem;
  border: none;
  font-size: 1rem;
  color: #1f2937;
}

.username-input input:focus {
  outline: none;
}

/* Error Messages */
.error-message {
  color: #dc2626;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* Login Footer */
.login-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
}

.login-footer p {
  font-size: 1rem;
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.register-link,
.forgot-password-link {
  color: #2563eb;
  text-decoration: none;
}

.register-link:hover,
.forgot-password-link:hover {
  text-decoration: underline;
}

/* Submit Button */
.login-button {
  background-color: #b91c1c;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
}

.login-button:hover {
  background-color: #991b1b;
}

/* Responsive Design */
@media (max-width: 480px) {
  .login-form-container {
    padding: 1.5rem;
  }

  .login-heading h2 {
    font-size: 1.5rem;
  }

  .login-button {
    font-size: 0.9rem;
  }
}
.loading-spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
  margin-right: 8px;
  display: inline-block;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

