/* PaymentDetails.css */

.payment-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.payment-details p {
    margin: 0;
    padding: 8px;
    background-color: #f8f9fa;
    border-radius: 5px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
}

.payment-details strong {
    font-weight: bold;
    color: #333;
}
