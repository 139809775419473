/* src/style/Newsfeed/Feed.css */

.feed-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
}

.feed-title {
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
  color: #333;
}

.feed-subtitle {
  text-align: center;
  margin-bottom: 20px;
  color: #555;
}

.posts-list {
  margin-top: 20px;
}

.post-item {
  background: #fff;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.post-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.post-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #f0f0f0;
  border: 2px solid #007bff;
}

.post-username {
  font-weight: bold;
  color: #333;
  font-size: 1.1rem;
}

.post-date {
  font-size: 0.8rem;
  color: #777;
}

.post-content {
  font-size: 1rem;
  margin-bottom: 15px;
  color: #333;
  line-height: 1.6;
  white-space: pre-wrap;
}

.post-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eee;
  padding-top: 10px;
}

.like-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.post-likes {
  font-size: 0.9rem;
  color: #555;
}

.like-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1;
  padding: 0;
  margin: 0;
  color: #ff6b6b;
  transition: transform 0.2s, color 0.2s;
}

.like-button:hover {
  transform: scale(1.2);
  color: #ff4757;
}

.comment-toggle-button {
  background: none; /* Ensure no background */
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  color: #007bff; /* Default text color */
  margin-top: 10px;
  transition: color 0.2s ease; /* Smooth transition for text color */
  padding: 0; /* Remove any default padding */
}

.comment-toggle-button:hover {
  color: #0056b3; /* Change text color on hover */
  background: none; /* Ensure no background on hover */
}

.comments-section {
  margin-top: 15px;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
}

.comments-list {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 15px;
}

.comment-item {
  background: #fff;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.comment-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.comment-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.comment-profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #eee;
}

.comment-username {
  font-weight: bold;
  color: #333;
}

.comment-date {
  font-size: 0.8rem;
  color: #777;
}

.comment-content {
  font-size: 0.9rem;
  color: #333;
  line-height: 1.4;
}

.no-comments {
  font-size: 0.9rem;
  color: #555;
  text-align: center;
  margin-top: 10px;
}

.add-comment-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.create-post-button {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: #fff;
  font-size: 2rem;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.create-post-button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Loader styles */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.post-media {
  margin-top: 10px;
}

.media {
  max-width: 100%;
  max-height: 400px;
  border-radius: 8px;
}

.media-input {
  margin-top: 10px;
}

/* Modern Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  background: #ffffff;
  padding: 25px;
  border-radius: 16px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  font-size: 1.5rem;
}

.post-textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  resize: vertical;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  background-color: #fafafa;
  color: #333;
  margin-bottom: 15px;
}

.post-textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

.post-textarea::placeholder {
  color: #999;
}

/* Modern File Input Styling */
.file-input-wrapper {
  margin-bottom: 15px;
}

.file-input {
  display: none; /* Hide the default file input */
}

.file-input-label {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px;
  border: 2px dashed #007bff;
  border-radius: 8px;
  background-color: #f0f8ff;
  color: #007bff;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.file-input-label:hover {
  background-color: #e1f0ff;
  border-color: #0056b3;
}

.file-icon {
  font-size: 1.2rem;
}

/* Modal Actions Styling */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal-cancel,
.modal-submit {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.modal-cancel {
  background: #f0f0f0;
  color: #333;
}

.modal-cancel:hover {
  background: #e0e0e0;
  transform: translateY(-2px);
}

.modal-submit {
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: #fff;
}

.modal-submit:hover {
  background: linear-gradient(135deg, #0056b3, #004080);
  transform: translateY(-2px);
}

.modal-submit:active {
  transform: translateY(0);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Mobile Optimization */
@media (max-width: 768px) {
  .feed-container {
    padding: 10px;
  }

  .feed-title {
    font-size: 1.5rem;
  }

  .feed-subtitle {
    font-size: 0.9rem;
  }

  .post-item {
    padding: 15px;
  }

  .post-header {
    gap: 8px;
  }

  .profile-pic {
    width: 40px;
    height: 40px;
  }

  .post-username {
    font-size: 1rem;
  }

  .post-date {
    font-size: 0.7rem;
  }

  .post-content {
    font-size: 0.9rem;
  }

  .like-button {
    font-size: 1rem;
  }

  .comment-toggle-button {
    font-size: 0.8rem;
  }

  .comments-section {
    padding: 10px;
  }

  .comment-item {
    padding: 8px;
  }

  .comment-profile-pic {
    width: 25px;
    height: 25px;
  }

  .comment-username {
    font-size: 0.9rem;
  }

  .comment-date {
    font-size: 0.7rem;
  }

  .comment-content {
    font-size: 0.8rem;
  }

  .create-post-button {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    bottom: 20px;
    right: 20px;
  }

  .modal-content {
    padding: 15px;
  }

  .modal-content h2 {
    font-size: 1.2rem;
  }

  .post-textarea {
    font-size: 0.9rem;
  }

  .modal-cancel,
  .modal-submit {
    padding: 8px 16px;
    font-size: 0.8rem;
  }
}

/* Modern Comment Input Section */
.add-comment-section {
  margin-top: 15px;
  background: #ffffff;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.comment-input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.comment-textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 0.9rem;
  resize: vertical;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  background-color: #fafafa;
  color: #333;
}

.comment-textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

.comment-textarea::placeholder {
  color: #999;
}

.add-comment-button {
  align-self: flex-end;
  background: linear-gradient(135deg, #007bff, #0056b3);
  border: none;
  border-radius: 8px;
  color: #fff;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background 0.3s ease, transform 0.2s ease;
}

.add-comment-button:hover {
  background: linear-gradient(135deg, #0056b3, #004080);
  transform: translateY(-2px);
}

.add-comment-button:active {
  transform: translateY(0);
}

.add-comment-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.button-text {
  font-weight: 500;
}

.button-icon {
  font-size: 1rem;
}


@media (max-width: 480px) {
  .feed-title {
    font-size: 1.2rem;
  }

  .feed-subtitle {
    font-size: 0.8rem;
  }

  .post-item {
    padding: 10px;
  }

  .profile-pic {
    width: 35px;
    height: 35px;
  }

  .post-username {
    font-size: 0.9rem;
  }

  .post-date {
    font-size: 0.6rem;
  }

  .post-content {
    font-size: 0.8rem;
  }

  .like-button {
    font-size: 0.9rem;
  }

  .comment-toggle-button {
    font-size: 0.7rem;
  }

  .comments-section {
    padding: 8px;
  }

  .comment-item {
    padding: 6px;
  }

  .comment-profile-pic {
    width: 20px;
    height: 20px;
  }

  .comment-username {
    font-size: 0.8rem;
  }

  .comment-date {
    font-size: 0.6rem;
  }

  .comment-content {
    font-size: 0.7rem;
  }

  .add-comment-section {
    gap: 6px;
  }

  .create-post-button {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
    bottom: 15px;
    right: 15px;
  }

  .modal-content {
    padding: 10px;
  }

  .modal-content h2 {
    font-size: 1rem;
  }

  .post-textarea {
    font-size: 0.8rem;
  }

  .modal-cancel,
  .modal-submit {
    padding: 6px 12px;
    font-size: 0.7rem;
  }
}