/* Ensure the page layout with sticky header and footer */
.manage-shipping-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* Sticky header */
  header {
    position: sticky;
    top: 0;
    z-index: 10;
    width: 100%;
    background-color: #333;
    color: white;
  }
  
  /* Main content area with padding and scroll */
  .manage-shipping-content {
    flex: 1;
    display: flex;
    padding: 40px 20px;
    gap: 20px;
    background-color: #f7f7f7;
  }
  
  /* Footer styling fixed at the bottom */
  footer {
    background-color: #333;
    color: white;
    padding: 10px 20px;
    text-align: center;
    margin-top: auto;
  }
  
  /* Page Heading and Paragraphs */
  h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 15px;
  }
  
  p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }
  
  /* Layout of Main and Sidebar Content */
  .main-content {
    flex: 2;
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 8px;
  }
  
  .right-sidebar {
    flex: 1;
    width: 250px;
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 8px;
    margin-left: 20px;
  }
  
  .right-sidebar h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .right-sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .right-sidebar li {
    padding: 8px 0;
    font-size: 16px;
    color: #555;
  }
  
  /* Search Input */
  .search-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Shipping Method Styles */
  .shipping-methods-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .shipping-method-item {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  .shipping-method-item:hover {
    transform: translateY(-5px);
  }
  
  .shipping-method-details {
    margin-left: 10px;
  }
  
  .shipping-method-details h3 {
    font-size: 18px;
    color: #333;
    margin: 0;
  }
  
  .shipping-method-details p {
    font-size: 14px;
    color: #666;
    margin: 5px 0 0;
  }
  
  /* Save Button Styling */
  .save-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #4caf50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .save-button:disabled {
    background-color: #ccc;
  }
  
  .save-button:hover:not(:disabled) {
    background-color: #45a049;
  }
  
  /* Saved Shipping Methods List */
  .saved-methods-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0;
  }
  
  .saved-method-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .saved-method-item h4 {
    font-size: 18px;
    color: #333;
    margin: 0;
  }
  
  .saved-method-item p {
    font-size: 14px;
    color: #666;
    margin: 5px 0;
  }
  
  /* Delete Button for Saved Methods */
  .delete-button {
    background-color: #dc3545;
    color: #fff;
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  