/* Header.css */

.header {
  background-color: #8B0000;
  color: white;
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-content {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  flex: 1;
}

.logo a {
  font-size: 28px;
  font-weight: bold;
  color: white;
  text-decoration: none;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  letter-spacing: 1px;
  transition: color 0.3s ease;
  white-space: nowrap; /* Ensures the title stays on one line */
}

.logo a:hover {
  color: #FFB6C1;
}

.search {
  flex: 2;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 20px;
}

/* 
  1) Increased right padding for the input 
     to avoid covering the button area.
*/
.search input[type="text"] {
  width: 100%;
  padding: 10px 15px;
  padding-right: 50px; /* <-- Reserve space for the search button */
  border-radius: 20px;
  border: none;
  font-size: 16px;
  height: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
}

.search input:focus {
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* 
  2) Elevate the button above .search-results (z-index: 101)
     and change the color to #fff for better visibility 
     on the dark red background.
*/
.search button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: black; /* Visible against dark red header */
  cursor: pointer;
  font-size: 18px;
  z-index: 101; /* Higher than .search-results (100) */
}

.nav-actions-container {
  display: flex;
  align-items: center;
}

.nav {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav a {
  color: white;
  text-decoration: none;
  font-size: 20px;
  transition: all 0.3s ease;
}

.nav a:hover {
  color: #FFB6C1;
  transform: scale(1.1);
}

.header-actions {
  margin-left: 20px;
}

.login-button,
.logout-button {
  color: white;
  text-decoration: none;
  font-size: 16px;
  padding: 8px 14px;
  border: 2px solid white;
  border-radius: 20px;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.logout-button {
  background-color: #8B0000; /* Matches mobile design */
}

.logout-button:hover {
  background-color: white;
  color: #8B0000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  z-index: 100;
  border-radius: 0 0 8px 8px;
  overflow-y: auto;
  max-height: 300px;
}

.search-result-item {
  padding: 12px 16px;
  border-bottom: 1px solid #e0e0e0;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease, padding-left 0.3s ease;
  font-size: 15px;
}

.search-result-item:last-child {
  border-bottom: none;
}

.search-result-item:hover {
  background-color: #f8f8f8;
  padding-left: 20px;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .header-content {
    flex-wrap: wrap;
    justify-content: center;
  }

  .logo {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .search {
    flex-basis: 100%;
    margin: 10px 0;
  }

  .nav-actions-container {
    flex-basis: 100%;
    justify-content: center;
    margin-top: 10px;
  }

  .nav {
    gap: 15px;
  }

  .nav a {
    font-size: 24px;
  }

  .header-actions {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .nav {
    gap: 10px; /* Reduces spacing between icons for smaller screens */
  }

  .search {
    margin: 10px 0;
  }

  .login-button,
  .logout-button {
    font-size: 18px;
    padding: 10px 20px;
  }

  .nav a {
    font-size: 22px;
  }
}

@media (max-width: 480px) {
  .nav {
    gap: 10px; /* Adds spacing between profile and logout button */
  }

  .nav a {
    font-size: 20px;
  }

  .logo a {
    font-size: 24px;
  }

  .login-button,
  .logout-button {
    font-size: 16px;
    padding: 8px 14px;
  }

  .search input[type="text"] {
    font-size: 14px;
  }
}

/* Desktop styles (default) */
.header {
  background-color: darkred;
  padding: 10px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .header {
    background-color: darkred;
    padding: 20px;
  }

  .nav-actions-container {
    display: flex; /* Keep items in a row */
    align-items: center; /* Center vertically */
    justify-content: space-between; /* Space out icons and buttons */
    flex-wrap: nowrap; /* Prevent wrapping */
  }

  .nav {
    display: flex;
    align-items: center;
    gap: 10px; /* Spacing between icons */
    flex-grow: 1; /* Take up remaining space */
  }

  .header-actions {
    display: flex;
    justify-content: flex-end; /* Push buttons to the right */
    gap: 10px; /* Spacing between buttons */
  }

  .login-button,
  .logout-button {
    font-size: 14px; /* Adjust button size for mobile */
    padding: 8px 12px;
  }
}

.nav-item {
  color: white;
  text-decoration: none;
  font-size: 20px;
  margin-left: 20px;
  transition: all 0.3s ease;
}

.nav-item:hover {
  color: #FFB6C1;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .nav-item {
    font-size: 18px;
    margin-left: 10px;
  }
}
