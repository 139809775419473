/* General Styles */
.checkout-page {
  min-height: 100vh;
  background-color: #f9fafb;
}

.loading-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9fafb;
}

.loading-text {
  font-size: 1.25rem;
  color: #4b5563;
}

.checkout-container {
  max-width: 1120px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.error-message {
  background-color: #fef2f2;
  color: #dc2626;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
}

/* Progress Steps */
.progress-steps {
  margin-bottom: 3rem;
}

.steps {
  display: flex;
  justify-content: space-between;
}

.step {
  flex: 1;
}

.step-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-box {
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 0 2rem;
  font-weight: 600;
}

.step-box .icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
}

.step.active .step-box {
  background-color: #b91c1c;
  color: #ffffff;
  border-radius: 0.5rem 0 0 0.5rem;
}

.step.active .step-arrow {
  width: 0;
  height: 0;
  border-top: 1.5rem solid transparent;
  border-bottom: 1.5rem solid transparent;
  border-left: 1.5rem solid #b91c1c;
}

.step:not(.active) .step-box {
  background-color: #e5e7eb;
  color: #4b5563;
  border-radius: 0 0.5rem 0.5rem 0;
}

/* Main Content Grid */
.main-content-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 1024px) {
  .main-content-grid {
    grid-template-columns: 2fr 1fr;
  }
}

/* Left Column */
.left-column {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Right Column */
.right-column {
  position: relative;
}

/* Section */
.section {
  background-color: #ffffff;
  border-radius: 0.75rem;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
  padding: 1.5rem;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.section-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1f2937;
  display: flex;
  align-items: center;
}

.section-title .icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  color: #b91c1c;
}

.new-address-button {
  background-color: #b91c1c;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
}

.new-address-button:hover {
  background-color: #991b1b;
}

/* No Address Message */
.no-address-message {
  text-align: center;
  color: #6b7280;
  padding: 2rem 0;
}

/* Address List */
.address-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.address-label {
  display: block;
}

.address-card {
  border: 2px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s;
}

.address-card:hover {
  border-color: #fecaca;
}

.address-card.selected {
  border-color: #b91c1c;
  background-color: #fef2f2;
}

.address-card-content {
  display: flex;
  align-items: flex-start;
}

.address-radio {
  margin-top: 0.25rem;
  margin-right: 1rem;
}

.address-details {
  flex: 1;
}

.address-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.recipient-name {
  font-weight: 600;
  color: #1f2937;
}

.address-type, .default-label {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 9999px;
}

.address-type {
  background-color: #dbeafe;
  color: #1e40af;
}

.default-label {
  background-color: #d1fae5;
  color: #065f46;
}

.address-line {
  color: #4b5563;
}

.mobile-number {
  color: #4b5563;
  margin-top: 0.5rem;
}

/* Shipping Methods */
.shipping-methods {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.shipping-method-label {
  display: block;
}

.shipping-method-card {
  border: 2px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s;
}

.shipping-method-card:hover {
  border-color: #fecaca;
}

.shipping-method-card.selected {
  border-color: #b91c1c;
  background-color: #fef2f2;
}

.shipping-method-content {
  display: flex;
  align-items: flex-start;
}

.shipping-radio {
  margin-top: 0.25rem;
  margin-right: 1rem;
}

.shipping-details {
  flex: 1;
}

.shipping-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.shipping-name {
  font-weight: 600;
  color: #1f2937;
}

.shipping-price {
  font-size: 1.125rem;
  font-weight: 600;
  color: #b91c1c;
}

.shipping-description {
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.estimated-delivery {
  font-size: 0.875rem;
  color: #2563eb;
}

/* Order Summary */
.order-summary {
  background-color: #ffffff;
  border-radius: 0.75rem;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
  padding: 1.5rem;
  position: sticky;
  top: 1.5rem;
}

.summary-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1f2937;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.summary-title .icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  color: #b91c1c;
}

.summary-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.summary-row {
  display: flex;
  justify-content: space-between;
  color: #4b5563;
}

.summary-message {
  padding: 1rem;
  font-size: 0.875rem;
  color: #c2410c;
  background-color: #fff7ed;
  border-radius: 0.5rem;
}

.summary-total {
  border-top: 1px solid #d1d5db;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  font-size: 1.125rem;
  font-weight: 600;
}

.continue-button {
  width: 100%;
  padding: 1rem 0;
  border-radius: 0.5rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.continue-button.disabled {
  background-color: #e5e7eb;
  color: #6b7280;
  cursor: not-allowed;
}

.continue-button:not(.disabled) {
  background-color: #b91c1c;
  color: #ffffff;
}

.continue-button:not(.disabled):hover {
  background-color: #991b1b;
}

/* Cart Items */
.cart-items {
  margin-top: 2rem;
}

.items-title {
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1rem;
}

.cart-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: #f9fafb;
  border-radius: 0.5rem;
}

.item-image {
  width: 5rem;
  height: 7rem;
  object-fit: cover;
  border-radius: 0.5rem;
}

.item-details {
  flex: 1;
}

.item-name {
  font-weight: 600;
  color: #1f2937;
}

.item-price {
  color: #b91c1c;
  font-weight: 600;
  margin-top: 0.5rem;
}

/* src/style/Checkout/CheckoutPart1.css */

/* Delivery Instructions Section */
.section {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.section-title {
  font-size: 1.2em;
  font-weight: 500;
  color: #333;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.section-title .icon {
  margin-right: 8px;
  color: #5a5a5a;
}

/* Textarea for Delivery Instructions */
.delivery-instructions-input {
  width: 100%;
  height: 80px;
  padding: 10px;
  font-size: 1em;
  color: #333;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  resize: vertical;
}

.delivery-instructions-input::placeholder {
  color: #999;
}

.delivery-instructions-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

.warning-message {
  color: #d9534f;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  text-align: center;
}



