.reviews-page-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem;
  font-family: Arial, sans-serif;
}

.page-title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 1rem;
}

.add-review-section {
  background-color: #f5f7fa;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
}

.section-title {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.review-form {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.dropdown, 
.comment-box,
.submit-btn {
  font-size: 1rem;
  margin-bottom: 1rem;
  padding: 0.75rem;
  border-radius: 4px;
}

.dropdown {
  border: 1px solid #ccc;
  background-color: #fff;
  width: 100%;
  max-width: 300px;
}

.comment-box {
  width: 100%;
  height: 80px;
  resize: vertical;
  border: 1px solid #ccc;
}

.star-rating-container {
  margin-bottom: 1rem;
}

.submit-btn {
  background-color: #3498db;
  color: #fff;
  width: fit-content;
  padding: 0.75rem 1.5rem;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s ease;
}

.submit-btn:hover {
  background-color: #2980b9;
}

.reviews-list {
  margin-top: 2rem;
}

.review-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.review-card p {
  margin: 0.5rem 0;
}

.no-reviews {
  font-style: italic;
  color: #555;
  text-align: center;
}

.loading-container {
  text-align: center;
  margin-top: 5rem;
}

.loading-spinner {
  margin: 0 auto 1rem;
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
