.process-user-payments {
    font-family: 'Arial', sans-serif;
    padding: 30px;
    background-color: #f0f2f5;
    max-width: 1200px;
    margin: 0 auto;
}

.process-user-payments h1 {
    text-align: center;
    font-size: 30px;
    color: #333;
    margin-bottom: 25px;
    font-weight: 700;
    text-transform: uppercase;
}

.payments-list {
    margin: 30px 0;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.payments-list h2 {
    font-size: 24px;
    color: #333;
    padding: 15px 20px;
    background-color: #38a169;
    color: #fff;
    margin: 0;
    font-weight: 700;
}

.payments-list table {
    width: 100%;
    border-collapse: collapse;
}

.payments-list th,
.payments-list td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #eaeaea;
    font-size: 15px;
    color: #555;
}

.payments-list th {
    background-color: #38a169;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
}

.payments-list tr:hover {
    background-color: #f9f9f9;
    transition: background-color 0.2s;
}

.payments-list input[type="radio"] {
    margin-left: 10px;
    cursor: pointer;
}

.status-update-section {
    background-color: #fff;
    padding: 25px;
    margin-top: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.status-update-section h2 {
    font-size: 22px;
    color: #333;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.status-update-section label {
    display: block;
    font-size: 16px;
    color: #444;
    margin-bottom: 8px;
    font-weight: 600;
}

.status-update-section input[type="text"],
.status-update-section textarea {
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 15px;
    color: #333;
    background-color: #f9f9f9;
    transition: border-color 0.2s;
}

.status-update-section input[type="text"]:focus,
.status-update-section textarea:focus {
    border-color: #38a169;
    outline: none;
}

.status-update-section textarea {
    height: 100px;
    resize: vertical;
}

.status-update-section button {
    background-color: #38a169;
    color: #fff;
    padding: 12px 25px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    transition: background-color 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.status-update-section button:hover {
    background-color: #45a049;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
