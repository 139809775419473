/* LandingPage.css */
:root {
  --primary-color: #8b0000;
  --secondary-color: #ffd700;
  --text-color: #333;
  --background-color: #f9f9f9;
  --white: #ffffff;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

.landing-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Hero Section */
.hero {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('../../../public/assets/img/hero1.webp');
  background-size: cover;
  background-position: center;
  color: var(--white);
  text-align: center;
  padding: 100px 20px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  min-height: 60vh;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  line-height: 1.6;
}

.cta-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.cta-button {
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--white);
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.cta-button.secondary {
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

/* Features Section */
.features {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  flex-wrap: wrap;
}

.feature {
  flex: 1;
  text-align: center;
  padding: 20px;
  min-width: 250px;
}

.feature i {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 20px;
}

/* Ad Space */
.ad-space {
  padding: 0 20px;
  width: 100%;
  max-width: 1900px;
  margin: 0 auto 50px auto;
}

.ad-container {
  padding: 0 2rem;
  text-align: center;
  width: 100%;
  position: relative;
}

.ad-content {
  width: 100%;
}

.ad-label {
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #3b82f6;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 4px;
  background-color: #eff6ff;
  border: 1px solid #dbeafe;
}

.ad-link {
  display: block;
  width: 100%;
}

.ad-image {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: contain;
  object-position: center;
  display: block;
  margin: 0 auto;
  background-color: #f5f5f5;
  border: none;
  box-sizing: border-box;
}

.ad-title {
  margin-top: 1rem;
  font-size: 1.5rem;
  color: var(--text-color);
}

.ad-description {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #666;
}

.ad-placeholder {
  width: 100%;
  height: 300px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ad-placeholder p {
  font-size: 1.5rem;
  color: #888;
}

/* Testimonials Section */
.testimonials {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 2rem;
  text-align: center;
  margin-bottom: 50px;
}

.testimonials h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.testimonial-container {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.testimonial {
  background-color: var(--white);
  border-radius: 8px;
  padding: 1.5rem;
  margin: 1rem auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.testimonial p {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-style: italic;
  color: #555;
}

.testimonial cite {
  display: block;
  font-size: 0.9rem;
  color: var(--secondary-color);
  margin-top: 1rem;
  font-style: normal;
  font-weight: bold;
}

.rating {
  font-weight: bold;
}

.rating span {
  font-size: 1.5rem;
  color: var(--secondary-color);
  margin: 0 2px;
}

/* Cookie Banner */
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cookie-banner p {
  margin: 0;
  font-size: 14px;
  text-align: center;
  flex: 1;
}

.terms-link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.terms-link:hover {
  color: #0056b3;
}

.close-banner-button {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: 2px solid #888;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 18px;
  color: #888;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}

.close-banner-button:hover {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
      padding: 50px 20px;
      min-height: 50vh;
  }

  .hero-content h1 {
      font-size: 2rem;
  }

  .hero-content p {
      font-size: 1.1rem;
  }

  .cta-container {
      flex-direction: column;
      gap: 15px;
  }

  .cta-button {
      width: auto;
      padding: 12px 24px;
  }

  .features {
      flex-direction: column;
      align-items: center;
  }

  .close-banner-button {
      width: 40px;
      height: 40px;
      font-size: 20px;
  }

  .cookie-banner p {
      font-size: 16px;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
      font-size: 1.8rem;
  }
  
  .hero-content p {
      font-size: 1rem;
  }
}

footer {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  text-align: center;
  margin-top: auto;
}