/* Profile.css */

/* General Styles */
.profile-page {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.profile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}

/* Profile Card */
.profile-card {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  padding: 1.5rem;
  flex: 1;
  min-width: 300px;
  max-width: 500px;
}

.profile-image-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}

.profile-image-container {
  position: relative;
  width: 120px;
  height: 120px;
  margin-bottom: 1rem;
}

.profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profile-image-upload-label {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.profile-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;
}

.admin-label {
  background-color: #f3f4f6;
  color: #111827;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.profile-details {
  margin-bottom: 1.5rem;
}

.profile-detail-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.profile-detail-label {
  flex: 0 0 120px;
  font-weight: bold;
  color: #4b5563;
}

.profile-detail-value {
  flex: 1;
  color: #6b7280;
}

.profile-detail-input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
}

.profile-edit-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #2563eb;
  padding: 0.5rem;
  transition: color 0.2s;
}

.profile-edit-button:hover {
  color: #1d4ed8;
}

.profile-save-button-container {
  display: flex;
  justify-content: center;
}

.profile-save-button {
  background-color: #10b981;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.2s;
}

.profile-save-button:hover {
  background-color: #059669;
}

/* Action Cards Container */
.action-cards-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 200px;
  gap: 1.5rem;
}

.action-card {
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  padding: 1.5rem;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.action-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.action-card h2 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #1f2937;
  margin-top: 0.75rem;
}

.action-card svg {
  color: #2563eb;
}

/* Loading and Error Messages */
.profile-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.profile-loading-spinner {
  border: 4px solid #e5e7eb;
  border-top: 4px solid #dc2626;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.profile-error-message {
  color: #dc2626;
  background-color: #fef2f2;
  padding: 1rem;
  border-radius: 0.375rem;
  max-width: 600px;
  margin: 2rem auto;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .profile-container {
    flex-direction: column;
    align-items: center;
  }

  .action-cards-container {
    width: 100%;
  }

  .profile-card,
  .action-cards-container {
    max-width: 100%;
  }

  .profile-detail-label {
    flex: 0 0 100px;
  }
}

@media (max-width: 480px) {
  .profile-detail-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-detail-label {
    margin-bottom: 0.25rem;
  }

  .profile-edit-button {
    align-self: flex-end;
  }

  .profile-save-button {
    width: 100%;
  }

  .profile-image-container {
    width: 100px;
    height: 100px;
  }

  .profile-name {
    font-size: 1.25rem;
  }

  .action-card h2 {
    font-size: 1rem;
  }

  .action-card svg {
    width: 40px;
    height: 40px;
  }
}
