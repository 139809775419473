/* src/style/Admin/Admin.css */

/* General Styles */
.admin-dashboard-container {
  background-color: #f9fafb;
  min-height: 80vh;
  padding: 2rem 1rem;
}

.admin-dashboard-content {
  max-width: 1200px;
  margin: 0 auto;
}

.admin-dashboard-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1f2937;
  text-align: center;
  margin-bottom: 2rem;
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 1rem;
}

/* Dashboard Cards */
.dashboard-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.dashboard-card {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  width: 250px;
  padding: 2rem 1.5rem;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
  overflow: hidden;
}

.dashboard-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 0%;
  height: 100%;
  background-color: rgba(59, 130, 246, 0.1);
  transform: translateX(-50%);
  transition: width 0.3s;
}

.dashboard-card:hover::before {
  width: 100%;
}

.dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.card-icon {
  color: #3b82f6;
  margin-bottom: 1rem;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 0.5rem;
}

.card-description {
  font-size: 1rem;
  color: #4b5563;
}

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard-cards {
    flex-direction: column;
    align-items: center;
  }

  .dashboard-card {
    width: 100%;
    max-width: 400px;
  }
}
