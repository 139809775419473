/* src/style/Admin/Orders/ProcessOrder.css */

/* General Styles */
.process-order-page {
    background-color: #f9fafb;
    min-height: 80vh;
    padding: 2rem 1rem;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .page-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1f2937;
    text-align: center;
    margin-bottom: 2rem;
    border-bottom: 2px solid #e5e7eb;
    padding-bottom: 1rem;
  }
  
  /* Loading Styles */
  .loading-container,
  .details-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .loading-container p,
  .details-loading p {
    margin-top: 1rem;
    font-size: 1.25rem;
    color: #4b5563;
  }
  
  /* Table Styles */
  .payments-table-wrapper {
    overflow-x: auto;
  }
  
  .payments-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
  }
  
  .payments-table th,
  .payments-table td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .payments-table th {
    background-color: #f3f4f6;
    font-size: 1rem;
    font-weight: 600;
    color: #374151;
  }
  
  .payments-table tbody tr:hover {
    background-color: #f9fafb;
    cursor: pointer;
  }
  
  .action-cell {
    width: 50px;
    text-align: center;
  }
  
  .payment-details-row {
    background-color: #f9fafb;
  }
  
  .payment-details {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .details-section {
    flex: 1 1 300px;
  }
  
  .details-section h2 {
    font-size: 1.25rem;
    color: #1f2937;
    margin-bottom: 0.5rem;
  }
  
  .details-section p {
    font-size: 1rem;
    color: #4b5563;
    margin-bottom: 0.25rem;
  }
  
  .process-button-container {
    margin-top: 1.5rem;
  }
  
  .process-button {
    background-color: #4A90E2;
    color: #ffffff;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .process-button:hover {
    background-color: #357ABD;
  }
  
  .no-payments,
  .no-details {
    font-size: 1.25rem;
    color: #4b5563;
    text-align: center;
    margin-top: 2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .payment-details {
      flex-direction: column;
    }
  
    .details-section {
      flex: 1 1 auto;
    }
  
    .page-title {
      font-size: 2rem;
    }
  }

  .important-notes {
    margin-top: 20px;
    padding: 15px;
    background-color: #ffefc2;
    border: 1px solid #ffc107;
    border-radius: 5px;
  }
  
  .important-notes h2 {
    margin: 0 0 10px;
    color: #d9534f;
  }
  
  .important-notes p {
    margin: 0;
    font-weight: bold;
    color: #333;
  }
  
  