/* src/style/Admin/UserManagement/UserPermissions.css */

/* General Styles */
.user-permissions-container {
    background-color: #f9fafb;
    min-height: 80vh;
    padding: 2rem 1rem;
  }
  
  .user-permissions-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .page-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1f2937;
    text-align: center;
    margin-bottom: 2rem;
    border-bottom: 2px solid #e5e7eb;
    padding-bottom: 1rem;
  }
  
  .no-requests-message {
    font-size: 1.25rem;
    color: #4b5563;
    text-align: center;
    margin-top: 2rem;
  }
  
  /* Table Styles */
  .permissions-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  
  .permissions-table th,
  .permissions-table td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .permissions-table th {
    background-color: #f3f4f6;
    font-size: 1rem;
    font-weight: 600;
    color: #374151;
  }
  
  .permissions-table tbody tr:hover {
    background-color: #f9fafb;
  }
  
  .permissions-table td {
    font-size: 0.975rem;
    color: #4b5563;
  }
  
  /* Action Buttons */
  .approve-button,
  .reject-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
    margin-right: 0.5rem;
    transition: color 0.2s;
  }
  
  .approve-button {
    color: #10b981; /* Green */
  }
  
  .approve-button:hover {
    color: #059669;
  }
  
  .reject-button {
    color: #ef4444; /* Red */
  }
  
  .reject-button:hover {
    color: #dc2626;
  }
  
  .approve-button:disabled,
  .reject-button:disabled {
    color: #9ca3af; /* Gray */
    cursor: not-allowed;
  }
  
  .approve-button:disabled:hover,
  .reject-button:disabled:hover {
    color: #9ca3af;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .permissions-table th,
    .permissions-table td {
      padding: 0.75rem;
    }
  
    .page-title {
      font-size: 2rem;
    }
  }
  