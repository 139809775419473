/* src/style/Business/SubmitRequestMessage.css */
.submit-request-message-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    background-color: #f7f9fc;
    padding: 20px;
  }
  
  .message-box {
    max-width: 500px;
    text-align: center;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .message-box .icon {
    font-size: 50px;
    color: #4caf50;
    margin-bottom: 20px;
  }
  
  .message-box h1 {
    font-size: 24px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .message-box p {
    font-size: 16px;
    color: #666666;
    margin-bottom: 20px;
  }
  
  .back-to-profile-button {
    display: inline-block;
    padding: 12px 24px;
    font-size: 16px;
    color: #ffffff;
    background-color: #4caf50;
    border-radius: 6px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .back-to-profile-button:hover {
    background-color: #45a049;
  }
  