/* src/style/Payment/PaymentPage.css */

/* General Styles */
.payment-page-container {
  background-color: #f9fafb;
  min-height: 80vh;
  padding: 2rem 1rem;
}

.payment-content-wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.page-title {
  font-size: 2rem;
  font-weight: bold;
  color: #1f2937;
  text-align: center;
  margin-bottom: 2rem;
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 1rem;
}

/* Cards */
.order-summary-card,
.bank-details-card,
.payment-reference-card,
.note-section-card,
.proof-of-payment-card {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.order-summary-card h2,
.bank-details-card h2,
.payment-reference-card h2,
.note-section-card h2,
.proof-of-payment-card h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1rem;
  border-bottom: 2px solid #3b82f6;
  padding-bottom: 0.5rem;
}

/* Details */
.summary-details,
.bank-details-content {
  margin-top: 1rem;
}

.summary-item,
.bank-details-content p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}

.detail-label {
  font-weight: 600;
  color: #4b5563;
}

.detail-value {
  color: #1f2937;
}

/* Payment Instructions */
.payment-instructions {
  margin-top: 1rem;
  color: #4b5563;
}

/* Payment Reference */
.payment-reference {
  font-size: 1.25rem;
  font-weight: bold;
  color: #1f2937;
  margin-top: 0.5rem;
}

/* Note Section */
.note-section-card textarea {
  width: 100%;
  min-height: 100px;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
  resize: vertical;
}

.submit-button {
  display: inline-flex;
  align-items: center;
  background-color: #3b82f6;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #2563eb;
}

.submit-button:disabled,
.submit-button[disabled] {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.button-icon {
  margin-right: 0.5rem;
}

.spinner-icon {
  animation: spin 1s linear infinite;
  margin-right: 0.5rem;
}

/* Proof of Payment */
.proof-of-payment-card .icon {
  color: #3b82f6;
  margin-right: 0.5rem;
}

.file-input-wrapper {
  position: relative;
  margin-bottom: 1rem;
}

.file-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.file-input-label {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
  text-align: center;
}

.file-input-label:hover {
  background-color: #e5e7eb;
}

/* Loading Spinner */
.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3b82f6;
}

/* Keyframes */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 640px) {
  .summary-item,
  .bank-details-content p {
    flex-direction: column;
    align-items: flex-start;
  }

  .detail-value {
    margin-top: 0.25rem;
  }

  .file-input-label,
  .submit-button {
    width: 100%;
    justify-content: center;
  }
}

.important-note-card {
  background-color: #fff5e6; /* Light yellow background for attention */
  border-left: 5px solid #ffa500; /* Orange border for emphasis */
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.important-note {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
}

.important-note strong {
  color: #d35400; /* Darker orange color for the 'Important' label */
}

