/* Categories.css */

/* General Styles */
.categories-page {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.genre-group {
  margin-bottom: 3rem;
}

.genre-title {
  font-size: 2rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #b91c1c;
  padding-bottom: 0.5rem;
}

.categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
}

/* Category Card */
.category-card {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: transform 0.2s;
}

.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.category-image-container {
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.category-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.category-info {
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.category-name {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0.5rem;
}

.category-description {
  font-size: 0.9rem;
  color: #4b5563;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3.6em; /* Limit to 3 lines */
  line-height: 1.2em;
}

.category-footer {
  padding: 0.75rem 1rem;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.category-price {
  font-size: 1.1rem;
  font-weight: 600;
  color: #b91c1c;
}

.category-actions {
  display: flex;
  gap: 0.5rem;
}

.icon-button {
  background-color: #f3f4f6;
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.icon-button:hover {
  background-color: #e5e7eb;
}

.icon-button i {
  font-size: 1rem;
  color: #1f2937;
}

/* Show More Button */
.show-more-button {
  display: block;
  margin: 2rem auto 0;
  background-color: #b91c1c;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.show-more-button:hover {
  background-color: #991b1b;
}

/* Loading and Error Messages */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.loading-spinner {
  border: 4px solid #e5e7eb;
  border-top: 4px solid #b91c1c;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.error-message {
  color: #dc2626;
  background-color: #fef2f2;
  padding: 1rem;
  border-radius: 0.375rem;
  max-width: 600px;
  margin: 2rem auto;
  text-align: center;
}

/* Filter Styles */
.filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #f8f9fa;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  flex-wrap: wrap;
}

.filter-container label {
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin-right: 10px;
}

.filter-container select {
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333;
  transition: border-color 0.3s;
}

.filter-container select:focus {
  outline: none;
  border-color: #007bff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .categories-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .filter-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-container label {
    margin-bottom: 5px;
  }

  .category-card {
    height: auto;
  }

  .category-image-container {
    height: 200px;
  }

  .category-description {
    max-height: none;
  }

  .genre-title {
    font-size: 1.5rem;
  }

  .show-more-button {
    width: 100%;
    margin-top: 1rem;
  }
}

@media (max-width: 480px) {
  .categories-grid {
    grid-template-columns: 1fr;
  }

  .category-image-container {
    height: 150px;
  }

  .category-name {
    font-size: 1rem;
  }

  .category-price {
    white-space: nowrap;
    font-size: 16px; /* Adjust size as needed */
    font-weight: bold; /* Optional styling */
  }
  
}

/* Filter Container */
.filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #f8f9fa;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  flex-wrap: wrap; /* Wrap for smaller screens */
}

/* Individual Filter Items */
.filter-item {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between label and select */
}

.filter-item label {
  font-size: 16px;
  color: #333;
  font-weight: 600;
}

.filter-item select {
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333;
  transition: border-color 0.3s;
}

.filter-item select:focus {
  outline: none;
  border-color: #007bff;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .filter-container {
    flex-direction: column; /* Stack filters vertically on small screens */
    gap: 15px;
  }

  .filter-item {
    width: 100%; /* Make filters full-width */
  }
}


