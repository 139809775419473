/* General Styles */
.manage-address-page {
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.manage-address-title {
  font-size: 2rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #b91c1c;
  padding-bottom: 0.5rem;
}

/* Tabs */
.address-tab-container {
  display: flex;
  margin-bottom: 1.5rem;
}

.address-tab-button {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  background-color: #f9fafb;
  color: #1f2937;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.address-tab-button:not(:last-child) {
  border-right: none;
}

.address-tab-button.active {
  background-color: #b91c1c;
  color: #ffffff;
}

.address-tab-button:hover {
  background-color: #e5e7eb;
}

/* Form Styles */
.address-form {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-row input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 1rem;
}

.form-actions {
  display: flex;
  gap: 1rem;
}

.address-form-button {
  background-color: #b91c1c;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.address-form-button:hover {
  background-color: #991b1b;
}

.cancel-button {
  background-color: #6b7280;
}

.cancel-button:hover {
  background-color: #4b5563;
}

/* Saved Addresses */
.saved-addresses-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1rem;
}

.address-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.address-card {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.address-card-details {
  flex: 1;
}

.address-card-actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.address-card-button {
  background-color: #b91c1c;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.address-card-button:hover {
  background-color: #991b1b;
}

.edit-button {
  background-color: #2563eb;
}

.edit-button:hover {
  background-color: #1d4ed8;
}

.delete-button {
  background-color: #dc2626;
}

.delete-button:hover {
  background-color: #b91c1c;
}

/* Loading and Error Messages */
.address-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.address-loading-spinner {
  border: 4px solid #e5e7eb;
  border-top: 4px solid #b91c1c;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.address-error-message {
  color: #dc2626;
  background-color: #fef2f2;
  padding: 1rem;
  border-radius: 0.375rem;
  max-width: 600px;
  margin: 2rem auto;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .address-card {
    flex-direction: column;
  }

  .address-card-actions {
    flex-direction: row;
    margin-top: 1rem;
  }
}
