/* ShippingManagement.css */

.shipping-management {
    background-color: #f9f9f9;
    min-height: 100vh;
  }
  
  .shipping-content {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
  }
  
  .shipping-header h2 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
  }
  
  .shipping-section {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .add-shipping,
  .existing-shipping {
    flex: 1 1 45%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .add-shipping h3,
  .existing-shipping h3 {
    margin-bottom: 20px;
    color: #555;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 6px;
    color: #555;
    font-weight: 500;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 80px;
  }
  
  .add-button {
    background-color: #28a745;
    color: #ffffff;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .add-button:hover {
    background-color: #218838;
  }
  
  .existing-shipping .shipping-list {
    margin-top: 20px;
  }
  
  .shipping-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fafafa;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #eee;
    border-radius: 6px;
  }
  
  .shipping-details h4 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .shipping-details p {
    margin-bottom: 8px;
    color: #555;
  }
  
  .shipping-details p strong {
    color: #333;
  }
  
  .button-group {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .update-button,
  .delete-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .update-button {
    background-color: #007bff;
    color: #ffffff;
  }
  
  .update-button:hover {
    background-color: #0069d9;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: #ffffff;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  
  .no-shipping {
    color: #777;
    text-align: center;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .shipping-section {
      flex-direction: column;
    }
  
    .add-shipping,
    .existing-shipping {
      flex: 1 1 100%;
    }
  
    .shipping-item {
      flex-direction: column;
    }
  
    .button-group {
      justify-content: flex-start;
    }
  }
  