.user-management-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #333;
  }
  
  .page-title {
    font-size: 28px;
    font-weight: bold;
    color: #4a90e2;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #777;
  }
  
  .user-table-container {
    overflow-x: auto;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
  }
  
  .user-table th,
  .user-table td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
    text-align: center;
  }
  
  .user-table th {
    background-color: #f4f6f9;
    font-weight: bold;
    color: #333;
  }
  
  .user-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .user-table tr:hover {
    background-color: #eaf3ff;
  }
  
  .no-users {
    font-size: 18px;
    color: #888;
    text-align: center;
    margin-top: 20px;
  }

  /* General styling */
.user-management-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
}

/* Sticky header */
.user-management-page header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: darkred;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Offset content to prevent overlap with sticky header */
.user-management-content {
  margin-top: 80px; /* Adjust based on header height */
}

/* Page title */
.page-title {
  font-size: 28px;
  font-weight: bold;
  color: #4a90e2;
  margin-bottom: 20px;
  text-align: center;
}

/* Table styling */
.user-table-container {
  overflow-x: auto;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
}

.user-table th,
.user-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.user-table th {
  background-color: #f4f6f9;
  font-weight: bold;
  color: #333;
}

.user-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.user-table tr:hover {
  background-color: #eaf3ff;
}

/* Loading and no-users message */
.loading,
.no-users {
  font-size: 18px;
  color: #888;
  text-align: center;
  margin-top: 20px;
}

/* Footer styling */
.user-management-page footer {
  margin-top: 40px;
  padding: 20px;
  text-align: center;
  background-color: #f4f6f9;
  color: #555;
}

/* Suspend Button Styling */
.suspend-button {
  background-color: #ff4d4d; /* Bright red color for attention */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover and Active Effects */
.suspend-button:hover {
  background-color: #cc0000; /* Darker red for hover */
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

.suspend-button:active {
  background-color: #b30000; /* Even darker red on click */
  transform: scale(0.95); /* Slightly shrink button on click */
}

/* Adding some margin around the button for spacing */
.user-table-container td:first-child .suspend-button {
  margin-right: 8px;
}


  