/* Updated CSS for Mobile-Friendly Design */

/* Container for the newsfeed */
.newsfeed-container {
  width: 100%; /* Use 100% width to fit the viewport */
  margin: 0;
  padding: 10px; /* Reduced padding for smaller screens */
  background-color: #f0f2f5;
}

/* Post container adjustments */
.posts-container {
  max-width: 600px; /* Reduced max-width for better mobile view */
  margin: 20px auto;
  padding: 0 10px; /* Reduced horizontal padding */
}

/* Header adjustments */
header {
  width: 100%;
  background-color: #f8f9fa;
  padding: 10px; /* Reduced padding */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 0;
}

/* Add Post Form Styling */
.add-post-form {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-bottom: 20px;
}

.add-post-form input,
.add-post-form textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-post-form button {
  padding: 10px;
  background-color: #1877f2;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-post-form button:hover {
  background-color: #0056b3;
}

/* Post card styling */
.post-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 15px; /* Reduced padding */
  margin-bottom: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.post-card:hover {
  transform: none; /* Removed hover transform for mobile */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Consistent shadow */
}

/* Post header */
.post-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.author-info h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.timestamp {
  font-size: 12px;
  color: #65676B;
}

.more-options {
  background: none;
  border: none;
  cursor: pointer;
  color: #65676B;
  font-size: 16px;
  margin-left: auto;
}

/* Post content */
.post-content {
  font-size: 14px;
  margin-bottom: 12px;
  line-height: 1.5;
}

/* Post image */
.post-image {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 12px;
  transition: opacity 0.3s ease;
}

.post-image:hover {
  opacity: 1; /* Removed hover effect for mobile */
}

/* Post actions */
.post-actions {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #E4E6EB;
  padding-top: 10px;
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #1877f2;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.action-button:hover {
  color: #1877f2; /* Consistent color on hover */
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .post-card {
    padding: 10px;
  }

  .post-content {
    font-size: 13px;
  }

  .author-info h3 {
    font-size: 14px;
  }

  .action-button {
    font-size: 13px;
  }
  
  .add-post-form {
    padding: 10px;
  }

  .add-post-form input, 
  .add-post-form textarea {
    font-size: 13px;
  }

  .add-post-form button {
    padding: 8px;
  }
}

/*  Loading */

.loading-container p {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
}


@media (max-width: 480px) {
  .categories-content {
    grid-template-columns: 1fr;
  }
  
  .genre-group h2 {
    font-size: 1.8rem;
  }

  
}

