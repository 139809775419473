/* General Styles */
.business-dashboard-page {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.business-dashboard-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 2rem;
  text-align: center;
}

.business-dashboard-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Dashboard Cards */
.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Display three large cards in a row */
  gap: 2rem;
}

.dashboard-card {
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px; /* Larger height for emphasis */
}

.dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.dashboard-card-icon {
  margin-bottom: 1.5rem;
  color: #2563eb;
}

.dashboard-card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #1f2937;
}

/* Loading and Error Messages */
.business-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.business-loading-spinner {
  border: 4px solid #e5e7eb;
  border-top: 4px solid #dc2626;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.business-error-message {
  color: #dc2626;
  background-color: #fef2f2;
  padding: 1rem;
  border-radius: 0.375rem;
  max-width: 600px;
  margin: 2rem auto;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard-cards {
    grid-template-columns: 1fr; /* Single column layout for small screens */
  }
}
