/* General Styles */
.complete-payment-container {
  background-color: #f9fafb;
  min-height: 80vh;
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.content-wrapper {
  max-width: 800px;
  width: 100%;
  padding: 1rem;
}

.page-title {
  font-size: 2rem;
  font-weight: bold;
  color: #1f2937;
  text-align: center;
  margin-bottom: 2rem;
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 1rem;
  position: sticky; /* Keeps title in place when scrolling */
  top: 0;
  background-color: #f9fafb;
  z-index: 10;
}

.payment-details-card {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Headers */
.payment-summary h2,
.upload-proof-section h2,
.bank-details h2,
.order-summary h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #3b82f6;
  padding-bottom: 0.5rem;
}

/* Detail Row */
.detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.detail-label {
  font-weight: 600;
  color: #4b5563;
}

.detail-value {
  color: #1f2937;
  font-weight: 600;
}

.status {
  font-weight: 600;
  color: #059669;
}

/* Upload Proof Section */
.upload-proof-section {
  margin-top: 2rem;
}

/* File Input */
.file-input-wrapper {
  position: relative;
  margin-bottom: 1rem;
}

.file-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.file-input-label {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.file-input-label:hover {
  background-color: #e5e7eb;
}

.file-icon {
  margin-right: 0.5rem;
  color: #3b82f6;
}

/* Upload Button */
.upload-button {
  display: inline-flex;
  align-items: center;
  background-color: #3b82f6;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.upload-button:hover {
  background-color: #2563eb;
}

.upload-button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.upload-icon {
  margin-right: 0.5rem;
}

/* Loading Spinner */
.spinner-icon {
  animation: spin 1s linear infinite;
  margin-right: 0.5rem;
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3b82f6;
}

/* Bank Details */
.bank-details {
  margin-top: 2rem;
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.bank-details-content p {
  margin-bottom: 0.5rem;
  color: #1f2937;
}

/* Order Summary */
.order-summary {
  margin-top: 2rem;
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.order-item {
  margin-bottom: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.order-item:last-child {
  border-bottom: none;
}

.order-item p {
  color: #1f2937;
  margin: 0.25rem 0;
}

.order-item strong {
  color: #4b5563;
}

/* Item Detail Row */
.item-detail-row {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0;
  width: 100%;
}

.detail {
  color: #1f2937;
}

.detail strong {
  color: #4b5563;
}

/* Spin Animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 640px) {
  .detail-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .file-input-label,
  .upload-button {
    width: 100%;
    justify-content: center;
  }
}
