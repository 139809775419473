.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7f7f7;
  padding: 20px;
}

.forgot-password-content {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}

p {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
  text-align: center;
}

.forgot-password-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 20px;
}

label {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  display: block;
}

.email-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: border-color 0.3s ease;
}

.email-input:focus {
  border-color: #FF1616;
  outline: none;
}

.submit-button {
  padding: 12px 24px;
  background-color: #FF1616;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.submit-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.submit-button:hover:enabled {
  background-color: #e60000;
}

.email-sent-confirmation {
  margin-top: 20px;
  font-size: 14px;
  color: #28a745;
  text-align: center;
}

@media (max-width: 480px) {
  .forgot-password-content {
    padding: 20px;
  }

  h1 {
    font-size: 20px;
  }

  p {
    font-size: 13px;
  }

  .email-input {
    font-size: 14px;
  }

  .submit-button {
    font-size: 14px;
    padding: 10px 20px;
  }
}