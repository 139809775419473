body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f7f7f7;
}

.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

main {
  padding: 20px 0;
}

.content-area {
  display: flex;
  gap: 20px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
