/* Cart.css */

/* General Styles */
.cart-page {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
  padding-top: 80px; /* Adjust this value based on your header height */
}

.cart-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media (min-width: 992px) {
  .cart-content {
    flex-direction: row;
  }
}

/* Cart Items Section */
.cart-items-section {
  flex: 2;
}

.cart-title {
  font-size: 2rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #b91c1c;
  padding-bottom: 0.5rem;
}

.cart-empty-message {
  font-size: 1.25rem;
  color: #4b5563;
  text-align: center;
  margin-top: 2rem;
}

.cart-seller-group {
  margin-bottom: 2rem;
}

.cart-seller-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1rem;
}

.cart-items-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cart-item-card {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  display: flex;
  padding: 1rem;
  align-items: center;
  position: relative;
}

.cart-item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-right: 1rem;
}

.cart-item-details {
  flex: 1;
}

.cart-item-name {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0.5rem;
}

.cart-item-price,
.cart-item-quantity {
  font-size: 1rem;
  color: #4b5563;
  margin-bottom: 0.25rem;
}

.cart-item-remove-button {
  background-color: transparent;
  border: none;
  color: #b91c1c;
  font-size: 1.25rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  transition: color 0.2s;
}

.cart-item-remove-button:hover {
  color: #991b1b;
}

.cart-seller-checkout {
  text-align: right;
  margin-top: 1rem;
}

.cart-checkout-button {
  background-color: #b91c1c;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cart-checkout-button:hover {
  background-color: #991b1b;
}

/* Cart Summary Section */
.cart-summary-section {
  flex: 1;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  padding: 1.5rem;
  height: fit-content;
}

.cart-summary-title {
  font-size: 1.75rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1.5rem;
}

.cart-summary-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cart-summary-row {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  color: #4b5563;
}

.cart-summary-total {
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: bold;
  color: #1f2937;
  border-top: 1px solid #e5e7eb;
  padding-top: 1rem;
  margin-top: 1rem;
}

/* Loading and Error Messages */
.cart-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.cart-loading-spinner {
  border: 4px solid #e5e7eb;
  border-top: 4px solid #b91c1c;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.cart-error-message {
  color: #dc2626;
  background-color: #fef2f2;
  padding: 1rem;
  border-radius: 0.375rem;
  max-width: 600px;
  margin: 2rem auto;
  text-align: center;
}

/* Quantity Controls */
.cart-item-quantity {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1rem;
  color: #4b5563;
  margin-top: 0.5rem;
}

.cart-item-quantity label {
  margin-bottom: 0.5rem;
}

.quantity-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.quantity-button {
  background-color: #b91c1c;
  color: #ffffff;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: bold;
}

.quantity-button:disabled {
  background-color: #e5e7eb;
  cursor: not-allowed;
}

.quantity-input {
  width: 3rem;
  text-align: center;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
}

/* Available Stock */
.available-stock {
  font-size: 0.85rem;
  color: #888;
  margin-top: 5px;
  text-align: left;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cart-content {
    flex-direction: column;
  }

  .cart-item-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-item-image {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .cart-item-remove-button {
    position: static;
    align-self: flex-end;
    margin-top: -2rem;
  }

  .cart-seller-checkout {
    text-align: center;
  }

  .cart-checkout-button {
    width: 100%;
  }

  .cart-summary-section {
    margin-top: 2rem;
  }

  .quantity-controls {
    width: 100%;
  }

  .quantity-input {
    flex: 1;
  }
}

@media (max-width: 480px) {
  .cart-title {
    font-size: 1.5rem;
  }

  .cart-seller-title {
    font-size: 1.25rem;
  }

  .cart-item-name {
    font-size: 1rem;
  }

  .cart-item-price,
  .cart-item-quantity {
    font-size: 0.9rem;
  }

  .cart-summary-title {
    font-size: 1.5rem;
  }

  .cart-summary-row,
  .cart-summary-total {
    font-size: 1rem;
  }

  .cart-checkout-button,
  .quantity-button {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }
}
