/* src/style/Header and Footer/DystenieHeader.css */

.dystenie-header {
    background-color: #000000; /* Jet black background */
    color: white;
    padding: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .dystenie-header-content {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .dystenie-logo {
    flex: 1;
  }
  
  .dystenie-logo a {
    font-size: 28px;
    font-weight: bold;
    color: white; /* White text for contrast */
    text-decoration: none;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    letter-spacing: 1px;
    transition: color 0.3s ease;
    white-space: nowrap;
  }
  
  .dystenie-logo a:hover {
    color: #cccccc; /* Light gray on hover */
  }
  
  .dystenie-search {
    flex: 2;
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 20px;
  }
  
  .dystenie-search input[type="text"] {
    width: 100%;
    padding: 10px 15px;
    padding-right: 50px;
    border-radius: 20px;
    border: none;
    font-size: 16px;
    height: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
  }
  
  .dystenie-search input:focus {
    outline: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .dystenie-search button {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: white; /* White icon for contrast */
    cursor: pointer;
    font-size: 18px;
    z-index: 101;
  }
  
  .dystenie-nav-actions-container {
    display: flex;
    align-items: center;
  }
  
  .dystenie-nav {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .dystenie-nav a {
    color: white;
    text-decoration: none;
    font-size: 20px;
    transition: all 0.3s ease;
  }
  
  .dystenie-nav a:hover {
    color: #cccccc; /* Light gray on hover */
    transform: scale(1.1);
  }
  
  .dystenie-header-actions {
    margin-left: 20px;
  }
  
  .dystenie-login-button,
  .dystenie-logout-button {
    color: white;
    text-decoration: none;
    font-size: 16px;
    padding: 8px 14px;
    border: 2px solid white;
    border-radius: 20px;
    transition: all 0.3s ease;
    white-space: nowrap;
  }
  
  .dystenie-logout-button {
    background-color: #000000; /* Jet black background */
  }
  
  .dystenie-logout-button:hover {
    background-color: white;
    color: #000000; /* Black text on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .dystenie-search-results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: white;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    z-index: 100;
    border-radius: 0 0 8px 8px;
    overflow-y: auto;
    max-height: 300px;
  }
  
  .dystenie-search-result-item {
    padding: 12px 16px;
    border-bottom: 1px solid #e0e0e0;
    color: #333;
    cursor: pointer;
    transition: background-color 0.2s ease, padding-left 0.3s ease;
    font-size: 15px;
  }
  
  .dystenie-search-result-item:last-child {
    border-bottom: none;
  }
  
  .dystenie-search-result-item:hover {
    background-color: #f8f8f8;
    padding-left: 20px;
  }
  
  /* Mobile Optimization */
  @media (max-width: 768px) {
    .dystenie-header-content {
      flex-direction: column;
      align-items: stretch;
      padding: 10px;
    }
  
    .dystenie-logo {
      text-align: center;
      margin-bottom: 10px;
    }
  
    .dystenie-logo a {
      font-size: 24px;
    }
  
    .dystenie-search {
      margin: 10px 0;
    }
  
    .dystenie-search input[type="text"] {
      font-size: 14px;
      height: 36px;
      padding: 8px 12px;
      padding-right: 40px;
    }
  
    .dystenie-search button {
      font-size: 16px;
      right: 10px;
    }
  
    .dystenie-nav-actions-container {
      flex-direction: column;
      gap: 10px;
    }
  
    .dystenie-nav {
      justify-content: center;
      gap: 15px;
    }
  
    .dystenie-nav a {
      font-size: 18px;
    }
  
    .dystenie-header-actions {
      margin-left: 0;
      text-align: center;
    }
  
    .dystenie-login-button,
    .dystenie-logout-button {
      font-size: 14px;
      padding: 6px 12px;
    }
  }
  
  @media (max-width: 480px) {
    .dystenie-logo a {
      font-size: 20px;
    }
  
    .dystenie-search input[type="text"] {
      font-size: 12px;
      height: 32px;
      padding: 6px 10px;
      padding-right: 35px;
    }
  
    .dystenie-search button {
      font-size: 14px;
      right: 8px;
    }
  
    .dystenie-nav {
      gap: 10px;
    }
  
    .dystenie-nav a {
      font-size: 16px;
    }
  
    .dystenie-login-button,
    .dystenie-logout-button {
      font-size: 12px;
      padding: 5px 10px;
    }
  }