/* src/style/Sell/Sell.css */

/* General Styles */
.sell-page-container {
  background-color: #f9fafb;
  min-height: 80vh;
  padding: 2rem 1rem;
}

.sell-form-wrapper {
  max-width: 800px;
  margin: 0 auto;
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.page-title {
  font-size: 2rem;
  font-weight: bold;
  color: #1f2937;
  text-align: center;
  margin-bottom: 2rem;
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 1rem;
}

.sell-form {
  width: 100%;
}

.form-section {
  margin-bottom: 2rem;
}

.form-section h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1rem;
  border-bottom: 2px solid #3b82f6;
  padding-bottom: 0.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  font-weight: 600;
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.form-group input[type='text'],
.form-group input[type='email'],
.form-group input[type='tel'],
.form-group input[type='number'],
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 1rem;
  color: #1f2937;
}

.form-group input[type='text']:focus,
.form-group input[type='email']:focus,
.form-group input[type='tel']:focus,
.form-group input[type='number']:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 1px #3b82f6;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.small {
  font-size: 0.875rem;
  color: #6b7280;
}

.submit-button {
  display: inline-flex;
  align-items: center;
  background-color: #3b82f6;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #2563eb;
}

.submit-button:disabled,
.submit-button[disabled] {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.button-icon {
  margin-right: 0.5rem;
}

.spinner-icon {
  animation: spin 1s linear infinite;
  margin-right: 0.5rem;
}

/* File Input Styles */
.file-input-wrapper {
  position: relative;
  margin-top: 0.5rem;
}

.file-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.file-input-label {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.file-input-label:hover {
  background-color: #e5e7eb;
}

.file-icon {
  margin-right: 0.5rem;
  color: #3b82f6;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 640px) {
  .sell-form-wrapper {
    padding: 1.5rem;
  }

  .submit-button {
    width: 100%;
    justify-content: center;
  }
}

.sell-button {
  display: inline-flex;
  align-items: center;
  background-color: #3b82f6;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.sell-button:hover {
  background-color: #2563eb;
}

.sell-button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.tutorial-link {
  margin-bottom: 20px;
  text-align: center;
}

.tutorial-link-anchor {
  color: #007BFF;
  text-decoration: underline;
  font-weight: bold;
}

.tutorial-link-anchor:hover {
  color: #0056b3;
  text-decoration: none;
}

.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 1rem;
  color: #1f2937;
  background-color: #ffffff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.form-group select:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 1px #3b82f6;
}

.tutorial-link {
  margin-bottom: 20px;
  text-align: center;
}

.tutorial-link p {
  margin-top: 40px; /* Add spacing above the paragraph */
}

.tutorial-link-anchor {
  color: #007BFF;
  text-decoration: underline;
  font-weight: bold;
}

.tutorial-link-anchor:hover {
  color: #0056b3;
  text-decoration: none;
}




