.category-details-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex; /* Align children side by side */
  justify-content: space-between; /* Spacing between content and buttons */
  align-items: flex-start; /* Align items to start to not stretch button container vertically */
}

.category-details-info-container {
  position: relative;
  flex: 1; /* Allows details to fill space */
}


.category-details-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.button-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; 
  padding: 10px;
  margin-top: 320px; /* Increased from 320px to move everything lower */
}


.button {
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #FF1616;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
}

.button-cart {
  padding: 12px 24px; /* Bigger than the generic button */
  background-color: #FF1616;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 10px;
}

.button-wishlist {
  padding: 8px 16px; /* Smaller than the generic button */
  background-color: #ff4757; /* Different color for distinction */
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 10px;
}

/* CategoryDetails.css */

/* Loading Animation Styles */
.category-details-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem; /* Adjust as needed based on your layout */
}

.category-details-loading-spinner {
  border: 4px solid #e5e7eb; /* Light gray border */
  border-top: 4px solid #b91c1c; /* Red border on top */
  border-radius: 50%;
  width: 3rem; /* Spinner size */
  height: 3rem; /* Spinner size */
  animation: spin 1s linear infinite; /* Spin animation */
  margin-bottom: 1rem; /* Space between spinner and text */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Optional: Styling the loading text */
.category-details-loading-container p {
  font-size: 1.25rem;
  color: #4b5563; /* Dark gray text color */
}

/* CategoryDetails.css */

/* Loading Animation Styles */
.category-details-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem; /* Adjust as needed based on your layout */
}

.category-details-loading-spinner {
  border: 4px solid #e5e7eb; /* Light gray border */
  border-top: 4px solid #b91c1c; /* Red border on top */
  border-radius: 50%;
  width: 3rem; /* Spinner size */
  height: 3rem; /* Spinner size */
  animation: spin 1s linear infinite; /* Spin animation */
  margin-bottom: 1rem; /* Space between spinner and text */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Error Message Styles */
.category-details-error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh; /* Adjust as needed */
  color: #dc2626; /* Red color for errors */
  font-size: 1.25rem;
}

/* No Category Found Styles */
.category-details-no-category-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh; /* Adjust as needed */
  color: #4b5563; /* Dark gray color */
  font-size: 1.25rem;
}

/* Optional: Styling the loading text */
.category-details-loading-container p {
  font-size: 1.25rem;
  color: #4b5563; /* Dark gray text color */
}

.new-sticker {
  position: absolute;
  top: -20px; /* Position it above the buttons */
  left: 50%;   /* Center horizontally */
  transform: translateX(-50%); /* Offset by half its own width for perfect center */
  background-color: green;
  color: white;
  font-weight: bold;
  padding: 5px 20px;
  border-radius: 5px;
  z-index: 10;
  /* Remove the rotation so it's a straight line */
  /* transform: rotate(15deg);  <-- removed */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}





