.notifications-container {
    padding: 20px;
    max-width: 600px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.connection-status {
    font-size: 14px;
    color: #666;
}

.notification-list {
    list-style-type: none;
    padding: 0;
}

.notification-item {
    padding: 10px;
    background-color: #e0f7fa;
    margin: 5px 0;
    border-radius: 4px;
}
