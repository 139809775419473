/* src/style/Payment/ManagePayments.css */

/* General Styles */
.manage-payments-container {
  background-color: #f9fafb;
  min-height: 80vh;
  padding: 2rem 1rem;
}

.content-wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

.page-title {
  font-size: 2rem;
  font-weight: bold;
  color: #1f2937;
  text-align: center;
  margin-bottom: 2rem;
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 1rem;
}

/* Loading Spinner */
.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3b82f6;
}

.spinner-icon {
  animation: spin 1s linear infinite;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

/* No Payments */
.no-payments {
  text-align: center;
  color: #4b5563;
  margin-top: 2rem;
}

.no-payments-icon {
  font-size: 3rem;
  color: #9ca3af;
  margin-bottom: 0.5rem;
}

/* Table Styles */
.table-responsive {
  overflow-x: auto;
}

.payments-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.payments-table th,
.payments-table td {
  padding: 1rem;
  text-align: left;
  color: #1f2937;
}

.payments-table th {
  background-color: #f3f4f6;
  font-weight: 600;
  border-bottom: 1px solid #e5e7eb;
}

.payments-table tbody tr:nth-child(even) {
  background-color: #f9fafb;
}

.payments-table tbody tr:hover {
  background-color: #e5e7eb;
}

/* Status Badge */
.status-badge {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
}

.status-badge.pending {
  background-color: #fef3c7;
  color: #b45309;
}

.status-badge.completed {
  background-color: #d1fae5;
  color: #065f46;
}

/* Time Remaining */
.time-remaining {
  font-weight: 500;
  color: #1f2937;
}

/* Pay Button */
.pay-button {
  background-color: #3b82f6;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.pay-button:hover {
  background-color: #2563eb;
}

.pay-button:disabled,
.pay-button[disabled] {
  background-color: #9ca3af;
  cursor: not-allowed;
}

/* Keyframes */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 640px) {
  .payments-table th,
  .payments-table td {
    padding: 0.75rem;
  }

  .pay-button {
    width: 100%;
    margin-top: 0.5rem;
  }
}
